<template>
	<!-- 添加编辑土地订单服务信息 -->
	<div class="land-work-service el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
			<a-form-item label="操作类型名称">
				<a-input v-model:value="form.title" placeholder="请输入操作类型名称"></a-input>
			</a-form-item>
			<a-form-item label="添加操作项">
				<div class="kws-item" v-for="(item,index) in form.workorderdetails" :key="index">
					<div class="kws-item-title">
						<div>操作项{{index}}</div>
						<div @click="operationItem(index)">删除</div>
					</div>
					<div class="kws-item-input">
						<div class="kws-item-input-title">操作名称</div>
						<a-input placeholder="请输入操作名称" style="width: 70%;" v-model:value="item.title"></a-input>
					</div>
				</div>
				<div class="kws-btn" @click="operationItem(-1)">
					<i class="ri-add-line"></i>添加操作项
				</div>
			</a-form-item>
			<a-form-item :wrapper-col="{offset:2}">
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import adoptModel from '@/api/adopt.js'
export default{
	name:"page-adopt-work-order",
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			form:{
				id:0,
				title:"",
				workorderdetails:[{
						title:'',
					}
				]
			},
		})
		const opt = useRoute().query
		if( opt.id ) adoptModel.getAdoptWorkDetail(opt.id,res=>{
			_d.form.id = res.id
			_d.form.title = res.title
			_d.form.workorderdetails = res.details
		})
		
		function operationItem(index){
			if( index == -1 ){
				_d.form.workorderdetails.push({
					title:'',
					company:'',
					bind_action:0
				})
				return
			}
			if( _d.form.workorderdetails[index].id ){
				adoptModel.deleteAdoptWorkItem(_d.form.workorderdetails[index].id,()=>_d.form.workorderdetails.splice(index,1))
				return
			}
			_d.form.workorderdetails.splice(index,1)
		}
		const submitSave = ()=>adoptModel.addOrEditAdoptWork(_d.form)
		return{
			...toRefs(_d),
			operationItem,
			submitSave
		}
	},
}
</script>

<style lang="scss">
	.land-work-service{
		.kws-item{
			width: 500px;
			border: 1px solid #f4f4f4;
			padding: 0 12px;
			box-sizing: border-box;
			margin-bottom: 12px;
			
			&-title{
				display: flex;
				height: 50px;
				line-height: 50px;
				justify-content: space-between;
				cursor: pointer;
			}
			
			&-input{
				margin-bottom: 12px;
				display: flex;
				align-items: center;
				&-title{
					width: 80px;
					color: #666;
				}
			}
		}
		.kws-btn{
			width: 500px;
			text-align: center;
			height: 40px;
			line-height: 40px;
			border: 1px dashed #d8d8d8;
			margin-top: 16px;
			cursor: pointer;
			color: #999;
		}
	}
	
</style>
